import { graphql, Link } from 'gatsby';
import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Quicklinks from '../components/Quicklinks';
import Section from '../components/Section';
import Video from '../components/Video';
import bgImage from '../images/CPdetail-image-videostill-bkgd-Earnell.jpg';

const CareerProfile = ({ data, pageContext: { orderLookup, relatedMap } }) => {
  const profile = data.allCareerProfilesYaml.edges[0].node;
  const sortIndex = parseInt(orderLookup[profile.parent.name]);
  const maxCareerIndex = parseInt(orderLookup['max']);

  return (
    <Layout
      title={`${profile.title} | Career Profiles`}
      className="career-profile"
    >
      <Section>
        <Container fullWidth>
          <Row>
            <Column>
              <p>
                <Link to="/educator-resources/career-profiles">
                  &lsaquo; View All
                </Link>
              </p>
              <h2>{profile.title}</h2>
              <div
                className="career-profile__video-wrapper"
                style={{ backgroundImage: `url('${bgImage}')` }}
              >
                <div className="career-profile__link-container">
                  <Link
                    className="career-profile__link"
                    to={`/career-profiles/${
                      orderLookup[
                        sortIndex - 1 === 0 ? maxCareerIndex : sortIndex - 1
                      ]
                    }`}
                  >
                    <Icon name="arrowleft" />
                  </Link>
                </div>
                <div className="career-profile__video-container">
                  <Video guidSrc={profile.videoSrc} />
                </div>
                <div className="career-profile__link-container">
                  <Link
                    className="career-profile__link"
                    to={`/career-profiles/${
                      orderLookup[
                        sortIndex + 1 > maxCareerIndex ? 1 : sortIndex + 1
                      ]
                    }`}
                  >
                    <Icon name="arrowright" />
                  </Link>
                </div>
              </div>
            </Column>
          </Row>
          <Row>
            <Column size={8}>
              <h3>{profile.introHeader}</h3>
              <p>{profile.introParagraph}</p>
              <Quicklinks
                title="Quick Links"
                smoothScroll={true}
                links={[
                  {
                    target: '#about',
                    label: 'About',
                  },
                  {
                    target: '#evaluate',
                    label: 'Evaluate Your Interest',
                  },
                  {
                    target: '#activateYourInterests',
                    label: 'Activate Your Interest',
                  },
                ]}
              />
              <h3 id="about" className="mt-2">
                About
              </h3>
              <p>{profile.about}</p>
              <p>
                {profile.careerProfilePDF && (
                  <div>
                    <Button to={profile.careerProfilePDF} target="_blank">
                      <Icon name="download" marginRight /> Career Profile
                    </Button>
                  </div>
                )}
              </p>
              <Image filename="CPdetail-icon-evaluate.svg" />
              <h4 id="evaluate" style={{ textTransform: 'uppercase' }}>
                Evaluate Your Interest
              </h4>
              <p>
                Explore the profile to learn more about the skills students need
                to succeed in this career:
              </p>
              <ul>
                {profile.evaluateChecklist &&
                  profile.evaluateChecklist.map((item) => {
                    return (
                      <li key={item} className="mb-0.5">
                        {item}
                      </li>
                    );
                  })}
              </ul>
              <Image filename="CPdetail-icon-activate.svg" />
              <h4
                id="activateYourInterests"
                className="mt-1"
                style={{ textTransform: 'uppercase' }}
              >
                Activate Your Interests
              </h4>
              {profile.activateIntro && <p>{profile.activateIntro}</p>}
              <ul>
                {profile.activateChecklist.map((item) => {
                  return (
                    <li key={item} className="mb-0.5">
                      {item}
                    </li>
                  );
                })}
              </ul>
              <p className="mt-1">
                {profile.studentActivationPDF && (
                  <Button to={profile.studentActivationPDF} target="_blank">
                    <Icon name="download" marginRight />
                    Student Activation (PDF)
                  </Button>
                )}
              </p>
            </Column>
            <Column size={4}>
              <Image
                filename={profile.quoteImage}
                className="career-profile__profile-image"
              />
              <div className="profile-quote mt-2">
                <p
                  className="career-profile__quote"
                  dangerouslySetInnerHTML={{ __html: profile.quote }}
                />
                <p
                  className="profile-quote__attribution"
                  dangerouslySetInnerHTML={{ __html: profile.quoteAttribution }}
                />
              </div>
            </Column>
          </Row>
          <Row className="mt-3">
            <Column size={7}>
              <h3>Additional Careers to explore</h3>
              <p>{profile.additionalCareersParagraph}</p>
            </Column>
          </Row>
          <Row>
            <Column size={6}>
              {profile.related[0] && (
                <>
                  <Image
                    filename={relatedMap[profile.related[0]].relatedImage}
                  />
                  <h3 className="mt-1 career-profile__add-career-title">
                    {relatedMap[profile.related[0]].relatedTitle}
                  </h3>
                  <p style={{ fontWeight: 'bold' }}>
                    {relatedMap[profile.related[0]].relatedSubHeader}
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[0]].shortDescription,
                    }}
                  />
                  <Button to={`/career-profiles/${profile.related[0]}`} link>
                    Learn More
                  </Button>
                </>
              )}
            </Column>
            <Column size={6}>
              {profile.related[1] && (
                <>
                  <Image
                    filename={relatedMap[profile.related[1]].relatedImage}
                  />
                  <h3 className="mt-1 career-profile__add-career-title">
                    {relatedMap[profile.related[1]].relatedTitle}
                  </h3>
                  <p style={{ fontWeight: 'bold' }}>
                    {relatedMap[profile.related[1]].relatedSubHeader}
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[1]].shortDescription,
                    }}
                  />
                  <Button to={`/career-profiles/${profile.related[1]}`} link>
                    Learn More
                  </Button>
                </>
              )}
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0">
        <Image filename={profile.bottomPicture} />
      </Section>
    </Layout>
  );
};

export default CareerProfile;

export const query = graphql`
  query CareerTemplateQuery($id: String!) {
    allCareerProfilesYaml(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          sortIndex
          about
          careerProfilePDF
          additionalCareersParagraph
          activateIntro
          activateChecklist
          bottomPicture
          evaluateChecklist
          introHeader
          introParagraph
          related
          relatedImage
          relatedSubHeader
          relatedTitle
          studentActivationPDF
          videoSrc
          quoteImage
          quote
          quoteAttribution
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`;
